var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{attrs:{"clearable":"","label":"Discord ID","outlined":""},on:{"blur":_vm.fetchTransactions}}),(_vm.info)?_c('AdminTransactionInfo',{attrs:{"info":_vm.info}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredTransaction,"mobile-breakpoint":"400","loading":_vm.fetching,"loading-text":"Fetching users...","item-class":_vm.rowBackground},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"user-info"},[_c('v-avatar',{attrs:{"size":"32"}},[_c('v-img',{attrs:{"src":item.avatarUrl,"alt":"avatar"}})],1),_c('div',{staticClass:"user-info-text"},[_c('span',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(item.username)+" ")]),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(item.id)+" ")])])],1)]}},{key:"item.card",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"card-info"},[_c('span',[_vm._v(" "+_vm._s(item.cc_num)+" ")]),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(item.cc_zip)+" ")])])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"card-info"},[_c('span',[_vm._v(" "+_vm._s(item.status)+" ")]),_c('span',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(item.reason)+" ")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('AdminUserReportModal',{attrs:{"user":item}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }